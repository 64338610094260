import React from "react"
import TextLoop from "react-text-loop";

class Logotype extends React.Component {
  render() {

    // using https://www.npmjs.com/package/react-text-loop
    return (
      <div
       style = {{
         display: 'inline'
       }}
      >
        human(<span style={{fontWeight: 200, color: '#0DC1ED'}}>
        <TextLoop
          springConfig={{ stiffness: 70, damping: 31 }}
          interval={5000}
          adjustingSpeed={500}
          children={["Ethos", "Potential", "Kindness", "Imagination", "Virtue",
                    "Collaboration", "Ethos", "Future", "Humans", "Leaders",
                    "Vision", "Team", "Being"]}
        />
        </span>)
      </div>
    )
  }
}

export default Logotype
