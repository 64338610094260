import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import { rhythm } from "../utils/typography"

import Logotype from "../components/logotype"

const HeaderTwo = styled(`h2`)`
  margin-bottom: ${rhythm(1.5)};
  margin-top: 0;
  font-family: Aleo, sans-serif;
  line-height: 2.415rem;
  font-size: 1.50533rem;
  @media (min-width: 410px) {
      font-size: 2.05811rem;
    }
`

class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    // if (location.pathname === rootPath && window.innerWidth >= 410) {
    if (location.pathname === rootPath) {
      header = (
        // <h2
        //   style={{
        //     ...scale(1.5),
        //     marginBottom: rhythm(1.5),
        //     marginTop: 0,
        //     fontFamily: `Aleo, sans-serif`,
        //   }}
        // >
        <HeaderTwo>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            <Logotype />
          </Link>
        </HeaderTwo>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Aleo, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            <Logotype />
          </Link>
        </h3>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <footer
          style = {{
            marginTop: rhythm(4),
            float: 'right'
          }}
        >
          © {new Date().getFullYear()}, David S Price
        </footer>
      </div>
    )
  }
}

export default Layout
